import { Modal, ModalHeader, ModalFooter, Button } from "reactstrap";
import clx from "classnames";

import { useEffect, useMemo, useState } from "react";
import { ApiKeysContainer } from "./ApiKeysContainer";
import { AppInfoContainer } from "./AppInfoContainer";
import { AppDisplayContainer, THEMES_MAP } from "./AppDisplayContainer";
import { AppLogsContainer } from "./AppLogsContainer";
import { AppTransactionsContainer } from "./AppTransactionsContainer";
import { LambdaContainer } from "./LambdaContainer";
import { useForm } from "../../../../hooks";
import { Loader } from "../../../../components";
import {
  LIGHT_THEME,
  FAST_TRANSACTION_SPEED,
  INSANE_TRANSACTION_SPEED,
  ENABLED,
  APP_MENU_TABS,
  APP_TYPE_BLOCKCHAIN,
  TAB_VIEW_WALLET,
  TAB_INFO,
  TAB_KEYS,
  TAB_DISPLAY,
  TAB_LOGS,
  TAB_LAMBDA,
  TAB_TRANSACTIONS,
  TAB_STATS,
  CHAIN_CONFIG,
  TAB_TYPES,
} from "../../../../constants";
import styles from "./index.module.scss";
import { AppStatsContainer } from "./AppStatsContainer";
import {
  AUTH_PROVIDER_STATE,
  ApiKey,
  CTA_BUTTON_STYLE,
  PROCESSING_LOADER_STATE,
  ParsedApp,
  UpdatedAppValues,
  GET_WALLET_AUTH,
} from "../../../../utils";

const TRANSACTION_SPEED_MAP = {
  [FAST_TRANSACTION_SPEED]: "Fast",
  [INSANE_TRANSACTION_SPEED]: "Insane",
};

const keysToCheck = [
  "name",
  "displayName",
  "appLogoUrl",
  "secondaryColor",
  "skipFinalScreen",
  "appTheme",
  "whitelistedOrigins",
  "transactionSpeed",
  "userMessageTemplate",
  "customCss",
  "state",
  "apiSignatureRequired",
  "hideWalletOperationDetailsSection",
  "hideUserProfileSection",
  "ctaButtonStyle",
  "fontName",
  "fontSizeScaling",
  "authProviders",
  "processingLoader",
  "getWalletAuth",
];

export type ParsedAppWithNewApiKey = ParsedApp & {
  // This is available only when user creates a new app
  privateKey?: string | null;
};

interface NewApiKey extends ApiKey {
  signedMsg: string;
}

interface DetailedAppModalProps {
  selectedApp: ParsedAppWithNewApiKey;
  onClose: () => void;
  isOpen: boolean;
  currentTabProp: TAB_TYPES | null;
  onUpdateHandler: (data: UpdatedAppValues) => Promise<void>;
}

export const DetailedAppModal = ({
  selectedApp = {} as ParsedAppWithNewApiKey,
  onClose,
  isOpen,
  currentTabProp = null,
  onUpdateHandler,
}: DetailedAppModalProps) => {
  const [activeTab, setActiveTab] = useState(TAB_INFO);
  const [keysArr, setKeysArr] = useState<(ApiKey | NewApiKey)[]>([]);
  const [hasUpdates, setHasUpdates] = useState(false);
  const [emptyKeyNameIdx, setKeyIdx] = useState(-1);
  const [isMounted, setMounted] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [deletedKeys, setDeletedKeys] = useState<
    {
      apiKey: string;
    }[]
  >([]);

  const {
    apiKeysInfo = { apiKeys: [] },
    appId = "",
    privateKey = null,
  } = selectedApp;

  const {
    onChangeHandler,
    errors,
    values,
    setDirectValue,
  }: {
    onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
    errors: { [key: string]: string };
    values: UpdatedAppValues;
    setDirectValue: (key: string, value: any) => void;
  } = useForm({
    name: "",
    displayName: "",
    appLogoUrl: "",
    secondaryColor: "",
    skipFinalScreen: false,
    appTheme: LIGHT_THEME,
    transactionSpeed: FAST_TRANSACTION_SPEED,
    whitelistedOrigins: [],
    userMessageTemplate: "",
    customCss: "",
    state: ENABLED,
    apiSignatureRequired: false,
    hideWalletOperationDetailsSection: false,
    hideUserProfileSection: false,
    ctaButtonStyle: "",
    fontName: "",
    fontSizeScaling: "1",
    processingLoader: PROCESSING_LOADER_STATE.ENABLED,
    authProviders: {
      phone: { state: "DISABLED" },
      email: { state: "DISABLED" },
    },
    getWalletAuth: GET_WALLET_AUTH.DEFAULT,
  });

  const {
    name = "",
    displayName = "",
    appLogoUrl = "",
    secondaryColor,
    skipFinalScreen,
    appTheme = LIGHT_THEME,
    transactionSpeed = FAST_TRANSACTION_SPEED,
    whitelistedOrigins = [],
    // @ts-ignore
    userMessageTemplate = "",
    // @ts-ignore
    customCss = "",
    state,
    apiSignatureRequired,
    hideWalletOperationDetailsSection,
    hideUserProfileSection,
    ctaButtonStyle = CTA_BUTTON_STYLE.ALLOW,
    fontName = "",
    fontSizeScaling = "1",
    authProviders = { phone: { state: AUTH_PROVIDER_STATE.DISABLED } },
    processingLoader = PROCESSING_LOADER_STATE.ENABLED,
    getWalletAuth = GET_WALLET_AUTH.DEFAULT,
  } = values;

  useEffect(() => {
    if (!selectedApp) return;

    for (const key in selectedApp) {
      if (key === "userWallet") {
        setDirectValue(
          "userMessageTemplate",
          selectedApp[key]?.userMessageTemplate
        );
        setDirectValue("customCss", selectedApp[key]?.customCss);
        setDirectValue("ctaButtonStyle", selectedApp[key]?.ctaButtonStyle);
        setDirectValue("fontName", selectedApp[key]?.fontName);
        setDirectValue("fontSizeScaling", selectedApp[key]?.fontSizeScaling);
        setDirectValue("authProviders", selectedApp[key]?.authProviders);
        setDirectValue("processingLoader", selectedApp[key]?.processingLoader);
        setDirectValue("getWalletAuth", selectedApp[key]?.getWalletAuth);
      }
      setDirectValue(key, selectedApp[key as keyof ParsedApp]);
    }
    setMounted(true);
  }, [selectedApp, setDirectValue]);

  useEffect(() => {
    if (currentTabProp) setActiveTab(currentTabProp);
  }, [currentTabProp]);

  useEffect(() => {
    if (!isMounted) return;
    const displayUpdated = keysToCheck.some((key) => {
      const existingValue = selectedApp[key as keyof ParsedApp] ?? "";
      const newValue = values[key as keyof UpdatedAppValues] ?? "";
      if (key === "whitelistedOrigins")
        return existingValue.toString() !== newValue.toString();
      if (key === "userMessageTemplate")
        return selectedApp.userWallet?.userMessageTemplate !== newValue;
      if (key === "ctaButtonStyle")
        return selectedApp.userWallet?.ctaButtonStyle !== newValue;
      if (key === "customCss")
        return selectedApp.userWallet?.customCss !== newValue;
      if (key === "fontName")
        return selectedApp.userWallet?.fontName !== newValue;
      if (key === "fontSizeScaling")
        return selectedApp.userWallet?.fontSizeScaling?.toString() !== newValue;
      if (key === "authProviders")
        return selectedApp.userWallet?.authProviders !== newValue;
      if (key === "processingLoader")
        return selectedApp.userWallet?.processingLoader !== newValue;
      if (key === "getWalletAuth") {
        return selectedApp.userWallet?.getWalletAuth !== newValue;
      }

      return existingValue !== newValue;
    });
    setHasUpdates(displayUpdated);
  }, [selectedApp, values, isMounted]);

  // Expensive check: Only changes when a new key is added
  // or when a existing key is updated
  const keysUpdated = useMemo(() => {
    if (!keysArr.length && !deletedKeys.length) return false;

    /*
      Checks if the apiKeysArray has the same keys as the apiKeys
      by comparing the api key and name of each object in the

      array. Returns false if the keys are the same, true otherwise.
    */

    let areSameKeys =
      keysArr.length === apiKeysInfo.apiKeys.length &&
      keysArr.every((item) =>
        apiKeysInfo.apiKeys.some(
          (key) =>
            key["apiKey"] === item["apiKey"] &&
            (key.name ?? "") === (item.name ?? "")
        )
      );
    return !areSameKeys;
  }, [apiKeysInfo, keysArr, deletedKeys]);

  useEffect(() => {
    if (!apiKeysInfo.apiKeys.length) return;
    const existingKeys = apiKeysInfo.apiKeys.map((key) => {
      // check if the privateKey is present
      // should be true only when user creates new app
      if (privateKey && apiKeysInfo.apiKeys.length === 1)
        return { ...key, apiSecret: privateKey };
      return { ...key };
    });
    setKeysArr(existingKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKeysInfo, privateKey]);

  const keyNameChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const currentState = [...keysArr];
    currentState[index].name = e.target.value;
    setKeysArr(currentState);
  };

  const isValid = () => {
    const { name } = errors;
    if (name) {
      setActiveTab(TAB_INFO);
      return false;
    }
    const keyWithEmptyName = keysArr.findIndex(
      (key) => key.hasOwnProperty("name") && !key.name
    );

    setKeyIdx(keyWithEmptyName);
    if (keyWithEmptyName !== -1) {
      setActiveTab(TAB_KEYS);
      return false;
    }
    return true;
  };

  const onSaveClick = async () => {
    const newKeys: UpdatedAppValues["newKeys"] = [];
    const updatedKeys: UpdatedAppValues["updatedKeys"] = [];
    if (!isValid()) return;
    keysArr.forEach((key) => {
      const { name, apiKey } = key;
      if ("signedMsg" in key)
        newKeys.push({
          ...(name && { name }),
          api_key: apiKey,
          ...(key.signedMsg && { signed_hello_message: key.signedMsg }),
        });
      else {
        const filteredKey = apiKeysInfo.apiKeys.filter(
          (k) => k.apiKey === apiKey
        )[0];
        filteredKey &&
          name &&
          filteredKey.name !== name &&
          updatedKeys.push({ name, api_key: apiKey });
      }
    });
    try {
      setLoading(true);
      await onUpdateHandler({
        ...values,
        newKeys,
        updatedKeys,
        deletedKeys,
        userWallet: {
          userMessageTemplate,
          customCss,
          ctaButtonStyle,
          fontName,
          fontSizeScaling,
        },
      } as UpdatedAppValues);
    } finally {
      setLoading(false);
    }
  };

  const onDeleteApiKey = (idx: number) => {
    const itemAtIdx = keysArr[idx];
    const currentState = [...keysArr];
    currentState.splice(idx, 1);
    setKeysArr(currentState);
    //if the key is newly created, just remove it from keys array
    // no need to add it in deleted list, new keys will have signedMsg
    if ("signedMsg" in itemAtIdx) return;
    const currentDeletedKeys = [...deletedKeys];
    currentDeletedKeys.push({ apiKey: itemAtIdx.apiKey });
    setDeletedKeys(currentDeletedKeys);
  };

  const getChildren = () => {
    switch (activeTab) {
      case TAB_INFO:
        return (
          <AppInfoContainer
            appId={appId}
            appName={name}
            onChangeHandler={onChangeHandler}
            errors={errors}
            state={state}
            apiSignatureRequired={apiSignatureRequired}
            setDirectValue={setDirectValue}
            appTypeData={selectedApp}
          />
        );
      case TAB_DISPLAY:
        return (
          <AppDisplayContainer
            appLogoUrl={appLogoUrl}
            displayName={displayName}
            secondaryColor={secondaryColor}
            onChangeHandler={onChangeHandler}
            setDirectValue={setDirectValue}
            skipFinalScreen={skipFinalScreen}
            appTheme={THEMES_MAP[appTheme]}
            whitelistedOrigins={whitelistedOrigins}
            userMessageTemplate={userMessageTemplate}
            customCssValue={customCss}
            hideWalletOperationDetailsSection={
              hideWalletOperationDetailsSection
            }
            hideUserProfileSection={hideUserProfileSection}
            ctaButtonStyle={ctaButtonStyle}
            fontName={fontName}
            fontSizeScaling={fontSizeScaling}
            authProviders={authProviders}
            processingLoader={processingLoader}
            getWalletAuth={getWalletAuth}
          />
        );
      case TAB_KEYS:
        return (
          <ApiKeysContainer
            keysArr={keysArr.sort(
              // @ts-ignore
              // TODO: Fix this later, string types can't be subtracted
              (a, b) => b.createdAtMillis - a.createdAtMillis
            )}
            onKeyNameChange={keyNameChange}
            setKeysArr={setKeysArr}
            errorAtIdx={emptyKeyNameIdx}
            onDeleteApiKey={onDeleteApiKey}
          />
        );
      case TAB_LAMBDA:
        return (
          <LambdaContainer
            transactionSpeed={TRANSACTION_SPEED_MAP[transactionSpeed]}
            setDirectValue={setDirectValue}
          />
        );
      case TAB_LOGS:
        return <AppLogsContainer appId={appId} />;
      case TAB_TRANSACTIONS:
        if (selectedApp.appType !== APP_TYPE_BLOCKCHAIN) return null;
        return (
          <AppTransactionsContainer
            appId={appId}
            chainId={selectedApp.blockchain.chainId}
          />
        );
      case TAB_STATS:
        return <AppStatsContainer appId={appId} appTypeData={selectedApp} />;
      default:
        return null;
    }
  };

  const children = getChildren();
  const disableSave = !hasUpdates && !keysUpdated;

  const isDisabled = state === "DISABLED";
  const isWideModal =
    activeTab === TAB_LOGS ||
    activeTab === TAB_STATS ||
    activeTab === TAB_TRANSACTIONS;
  return (
    <Modal
      centered
      isOpen={isOpen}
      className={clx(
        styles.edit_app_modal,
        isWideModal && styles.animated_detailed_app_modal
      )}
    >
      <ModalHeader>
        <div className="app-modal-title">{name}</div>
      </ModalHeader>
      <div className="tabs-wrapper">
        {APP_MENU_TABS.map((elm, idx) => {
          // We dont want to show TAB_VIEW_WALLET in detailed view
          if (elm.id === TAB_VIEW_WALLET) return null;
          if (
            elm.id === TAB_LAMBDA &&
            selectedApp.appType === APP_TYPE_BLOCKCHAIN &&
            selectedApp.blockchain.chainId &&
            !CHAIN_CONFIG[selectedApp.blockchain.chainId].showLambdaTab
          )
            return null;
          if (elm.supported_app_types.indexOf(selectedApp.appType) === -1)
            return null;
          return (
            <TabItem
              key={idx}
              onTabClick={setActiveTab}
              isActive={activeTab === elm.id}
              isDisabled={isDisabled && elm.id !== TAB_INFO}
              value={elm.id}
            />
          );
        })}
      </div>
      {children}
      <ModalFooter>
        <div className="app-modal-cta">
          <Button
            onClick={onClose}
            className={clx("primary-btn", "clr-transp")}
          >
            Close
          </Button>
          <Button
            disabled={disableSave}
            onClick={onSaveClick}
            className={clx("primary-btn")}
          >
            Save
          </Button>
        </div>
      </ModalFooter>
      {isLoading && <Loader />}
    </Modal>
  );
};

interface TabItemProps {
  isActive: boolean;
  isDisabled: boolean;
  value: TAB_TYPES;
  onTabClick: (value: TAB_TYPES) => void;
}

const TabItem = ({ isActive, isDisabled, value, onTabClick }: TabItemProps) => {
  const onTabItemClick = (value: TAB_TYPES) => {
    onTabClick(value);
  };
  return (
    <div
      onClick={() => {
        onTabItemClick(value);
      }}
      className={clx(
        "tab-item",
        isActive && "active",
        isDisabled && "disabled"
      )}
    >
      {value}
    </div>
  );
};
